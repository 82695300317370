.custom-cart-error {
    text-align: center;
}

.braintree-account-add-credit-card-content,
.payment-method-braintree:not(.braintree-account-add-credit-card-content .payment-method-braintree) {
    position: relative;
}

.payment-method-braintree .error {
    min-height: 1.5em;
    line-height: 1.5em;
    color: #f00;
    margin-top: -0.5em;
}

.payment-method-braintree .braintree-field-wrap {
    display: inline-block;
    width: 100%;
    margin: 0;
    position: relative;
    height: 2.25em;
}

.payment-method-braintree .braintree-field-wrap select {
    width: 100%;
}

.payment-method-braintree .braintree-hosted-fields-iframe-container {
    padding: 0 0.313em;
    width: 100%;
    height: 2.375em;
    border: 0.1rem solid #aaa;
    border-radius: .1875rem;
    position: absolute;
    left: 0;
    top: 0;
}

.payment-method-braintree .braintree-hosted-fields-to-display {
    position: absolute;
    left: 0;
    top: 0;
    height: 2.375em;
    width: 100%;
    padding: 0 0.313em;
    line-height: 2.375em;
    cursor: default;
    color: #aaa;
    border: 0.1rem solid #aaa;
    border-radius: .1875rem;
}

.payment-method-braintree .braintree-text-input {
    width: 100%;
    height: 2.375em;
    position: absolute;
    left: 0;
    top: 0;
    border: 0.1rem solid #ced4da;
    border-radius: .1875rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.payment-method-braintree .braintree-text-input input {
    padding: 0 0.313em;
    margin: 0;
    width: 100%;
    border: none;
    height: 2.125em;
    line-height: 2.125em;
    box-sizing: inherit;
    outline: none;
    box-shadow: none;
}

.payment-method-braintree .braintree-hosted-fields-focused {
    box-shadow: 0 0 0.2rem #ffdb4d;
}

.payment-method-braintree .braintree-hosted-fields-invalid {
    border-color: #ff0000;
}

.payment-method-braintree .braintree-hosted-fields-valid {
    border-color: #84bd00;
}

.braintree-make-default-card {
    border: none;
    background: transparent;
    color: #0070d2;
}

.braintree-add-new-card-btn {
    border: none;
    background: transparent;
    color: #0070d2;
    text-decoration: underline;
}

.braintreeCreditCardBtn {
    margin-bottom: 1rem;
}

.braintree-src-account-button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.braintree-loader-container {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.7;
    z-index: 990;
}

.braintree-loader {
    position: absolute;
    color: #383838;
    font-size: 0.4rem;
    background: #fff;
    opacity: 0.5;
    margin: auto;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@keyframes load {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.braintree-account-add-credit-card-content p {
    text-align: center;
    margin-top: .5rem;
}

.braintree-account-add-credit-card-content .braintree-hosted-fields-to-display {
    display: none;
}

.braintree-account-add-credit-card-content #braintreeFormErrorContainer {
    color: #f00;
    line-height: 1.5em;
    min-height: 1.5em;
}

.braintree-account-add-credit-card-content #braintreeSrcFormErrorContainer {
    color: #f00;
    line-height: 1.5em;
    min-height: 1.5em;
}

.braintree-credit-card-fields .cardNumber {
    display: none;
}

.braintree-cart-google-buttons-wrap {
    width: 100%;
    margin-bottom: 11px;
}

.braintree-cart-buttons-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.used-creditcard-account-hide {
    display: none;
}

.used-paypal-account-hide {
    display: none;
}

.braintree-cart-paypal-buttons-wrap {
    position: relative;
    vertical-align: middle;
    text-align: right;
    padding-bottom: 0.938em;
}

.braintree-cart-buttons-wrap .braintree-cart-paypal-buttons-wrap {
    width: 100%;
    padding: 0;
}

.braitnree-cart-paypal-button {
    display: inline-block;
    min-height: 2.188em;
    width: 100%;
    position: relative;
}

.applepay-button-div {
    width: 100%;
}

@media (max-width: 54.398rem) {
    .checkout-continue .braintree-cart-paypal-buttons-wrap .error {
        padding: 0;
    }

    .checkout-continue .braintree-cart-paypal-buttons-wrap .braitnree-cart-paypal-button {
        padding: 0;
    }
}

#braintree3DSecureContainer {
    display: none;
}

.js-braintree-pdp-button,
.js-braintree-pdp-button-global {
    padding: 1.5em;
}

.js-braintree-pvp-button,
.js-braintree-pvp-button-global {
    padding: 1.5em 0;
}

@media screen and (min-width: 76.8rem) {
    #braintree3DSecureContainer {
        padding-left: 22%;
    }
}

.braintree-cart-apple-buttons-wrap {
    text-align: right;
    padding-bottom: 0.938em;
    display: block;
    position: relative;
}

.braintree-cart-apple-button-wrap {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%;
    min-height: 2em;
}

.braintree-cart-apple-button {
    display: none;
}

.braintree-billingpage-apple-buttons-wrap {
    text-align: left;
    padding-bottom: 0.938em;
    display: block;
    position: relative;
}

.braintree-billingpage-apple-button-wrap {
    position: relative;
    vertical-align: middle;
    min-width: 15.625em;
    min-height: 2em;
}

.braintree-billingpage-apple-button {
    display: none;
}

.js-braintree-applepay-button_disabled {
    opacity: 0.4;
    cursor: default;
}

apple-pay-button {
    --apple-pay-button-width: 10rem;
    --apple-pay-button-height: 1.1875rem;
    --apple-pay-button-border-radius: 0.3125rem;
    --apple-pay-button-padding: 0.3125rem 0.5rem;

    display: initial;
}

@media (max-width: 54.398rem) {
    .checkout-continue .braintree-cart-apple-buttons-wrap .error {
        padding: 0;
    }

    .checkout-continue .braintree-cart-apple-buttons-wrap .braintree-cart-apple-button-wrap {
        padding: 0;
    }

    .checkout-continue .braintree-cart-apple-buttons-wrap .braintree-cart-apple-button {
        padding: 0;
    }
}

.used-venmo-account-hide {
    display: none;
}

.braintree-billingpage-venmo-button {
    width: 12rem;
    height: 4.8rem;
    background: url(../images/braintree-venmo-btn.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer
}

.braintree_accountVenmoButton {
    width: 15rem;
    height: 6rem;
    background: url(../images/braintree-venmo-btn.png) 0 0/10rem no-repeat;
    cursor: pointer
}

.venmoUsername {
    color: #000000;
    line-height: 2.5em;
}

#venmo-content .venmo-error {
    color: #f00;
    line-height: 1.5em;
    min-height: 1.5em;
}

.braintree-venmo-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: #fff 50% 50% url(../images/ajax-loader.gif) no-repeat;
    z-index: 100;
    text-align: center;
}

.lpmButton {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: none;
}

.lpm-button-image {
    width: 10rem;
    height: 3.2rem;
}

.braintree-cart-src-buttons-wrap {
    margin-bottom: 11px;
}

.v-button-zero-amount:hover {
    cursor: pointer;
    transition-property: filter;
    transition-duration: 0.25s;
    filter: brightness(90%) saturate(90%);
}

.used-googlepay-account-hide {
    display: none;
}

.card-body-bt:not(:last-child) {
    border-bottom: 0.1rem solid rgba(0, 0, 0, .125);
}

.remove-bt-payment,
.update-bt-billing-address {
    border: none;
    background: transparent;
    color: #0070d2;
    text-decoration: underline;
}

.add-paypal-account,
.add-venmo-account {
    color: #0070d2;
    cursor: pointer;
    text-decoration: underline;
}

.custom-checkbox {
    text-align: left;
}

.braintree-paypal-billing-button{
    width: 100%;
}

.btn-cwpp:hover {
    filter: brightness(1.1);
}

.flash-messages-container {
    position: fixed;
    width: 100%;
    top: 1rem;
    left: 0;
    padding: 0 1rem 0 1rem;
    z-index: 9999;
}

.flash-messages-container > .alert {
    width: fit-content;
    margin: 0 auto 1rem auto;
}

.paypal-info_title {
    text-align: center;
    font-size: 1.5rem;
    margin: 1em 0;
}

.automatic-payment-adding-paypal-button {
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
}

.automatic-payment-adding_error {
    text-align: center;
    color: red;
}

.hide-block {
    display: none;
}

.googlepay-container {
    display: flex;
    justify-content: center;
}

.position-top-right {
    top: 0;
    right: 0;
}

.badge-cc-min-width {
    min-width: 125px;
    padding: 0.4em;
}

@media screen and (max-width: 1024px) {
    .flash-messages-container > .alert {
        width: 100%;
    }
}

.braintree-modal-body {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1rem;  
}

.modal .braintree-modal-body {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
}

.braintree-modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.1875rem - 1px);
    border-top-right-radius: calc(0.1875rem - 1px);  
}
